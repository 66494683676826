.page {
  /* display: flex;
  flex-direction: row; */
  overflow: hidden;
  height: 100vh;
  /* background-color: #203864;
  color: white; */
}

.heading {
  top: 0;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
}
.naman {
  height: calc(100vh- 100px) !important;
}
.crosswordDiv {
  display: flex;
  justify-content: space-between;
}

.grid {
  width: 750px !important;
  /* height: 700px; */
  /* top: 0; */
  bottom: 0;
  margin-left: 10px;
  position: sticky;
}


.clues {
  /* left: 700px; */
  /* position:absolute; */
  max-height: calc(100vh - 90px);
  overflow:scroll;
  /* position: absolute; */
  /* right: 0; */
  width: calc(100vw - 800px);
}

text[dominant-baseline="hanging"] {
  font-weight: 900;
}

.language {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

/* .guess-text-correct {
  fill: #96B93D !important;
} */

.guess-text-incorrect {
  fill: red !important;
}


.correct {
  text-decoration: line-through;
}