.bgm {
  background-image: url(/public/backgroundImage.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  padding: 0% 25%;
  /* font-size: 24px; */
  /* padding: 200px 400px 200px 300px; */
  text-align: center;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

.form-control::placeholder {
  color: 	#C0C0C0;
}

.form-control, .btn {
  margin-top: 10px;
  margin-bottom: 10px;
}

.row {
  /* width: 100%; */
  margin-top: 20px;
}

.card {
  width: 500px;
  /* background-color: #96B93D; */
  /* color: white; */
}

label {
  font-weight: 600;
}

.btnSubmit {
  background-color: #96B93D;
  color: white;
}

.page1 {
  width: 100%;
}

td, th {
  /* padding-bottom: 10px; */
  min-width: 100px;
}

.table td:first-child {
  padding-left: 40px;
}

.table tr:last-child td{
  border-bottom-width: 0 !important;
}

.table tr:first-child th:first-child {
  border-top-left-radius: 10px;
}
  
.table tr:first-child th:last-child {
  border-top-right-radius: 10px;
}
.table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
  
.table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.input-group-prepend .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.input-group-append .form-control{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.marketDataTable {
  border-collapse: separate;
}

.marketDataTableRow {
  font-size: x-large;
  background-color: #f7f7f7;
  color: black;
}

.marketDataTableRow td, .marketDataTableRow th {
  min-width: 150px;
  border-radius: 50px;
  padding: 0 10px;
}

:nth-child(2 of .index-module_separator__7GhtE) {
  display: none;
}

.rateInputTable td, .rateInputTable th {
  max-width: 0px;
}

.joystickGif {
  width: 150px;
  margin: -10px;
  cursor: pointer;
}

.fine-print {
  font-size: small;
}

.errorMessage {
  color: red
}

tr:first-child .award{
  color: gold;
}

tr:nth-child(2) .award{
  color: silver;
}

tr:nth-child(3) .award{
  color: #CD7F32;
}

tr:nth-of-type(1n+4) .award{
  /* color: #89CFF0; */
  display: none;
}

td:hover {
  transform: scale(1.1);
}

.ratesTable {
  background-color: #ffc107;
  color: black;
  margin-bottom: 20px;
}

.appear {
  animation: example 0.1s linear;
}

.btn:hover .spin {
  animation: App-logo-spin infinite 1s linear;
}

.teamSelectRow {
  display: inline-block;
  width: fit-content;
}

.graphRow {
  width: max-content;
}

.iconGif {
  width: 55px;
}

.teamIcon {
  display: flex;
}

.teamIcon .iconGif {
  width: 55px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes example {
  from {
    transform: scaleY(0);
    transform-origin: top;
  }
  to {
    transform: scaleY(1);
  }
}

/*Bounce*/
@keyframes kreep {
  0% {-webkit-transform: scale(1.1,.9);
      transform: scale(1.1,.9);}
 50% { -webkit-transform: scale(.9,1.1) translateY(-.5rem)}
 70% { -webkit-transform: scale(1);
       transform: scale(1);}
}

.btn.jump {
  display: inline-block;
  font-weight: 800;
  background-color: #96b93d;
  /*name-duration-function(ease,eas-in,linear)-delay-count-direction */
  animation: kreep 0.7s ease 2s infinite alternate;
}
/*END BOUNCER*/
